import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Consumer} from "../context";
import {URL_API} from "../core/urlsApi";
import Request from "../api";
import swal from "sweetalert";
import { useParams } from "react-router-dom";


class RecoverPass extends React.Component{

	constructor(props) {
		super(props);
	}

	async updatepass(e) {
		e.preventDefault();
		const req = new Request();
		// Muestra overlay
		//props.context.showLoading();
		const form 			= e.target;
		const newpass 		= form.newpassword.value;
		const repeatpass 	= form.repeatpassword.value;
		const token 		= this.props.match.params.token;
		//const pass = form.password.value;
		if(newpass!=repeatpass){
			swal('Las contraseñas no coinciden', {
				icon: "warning",
			});
		}else{
			const password = newpass;
			const data = {
				password: password,
				token: token
			};
			const res = await req.post(URL_API + "/users/recover/password", data);
			if (res) {
				if (res.update) {
					swal(res.message, {
						icon: "success",
					});
				}else if(res.error){
					swal(res.message, {
						icon: "warning",
					});
				}
			}
		}

		
	}
	render() {
		return (
			<div className="home justify-center align-center">
				<div className="login">
					<form className="login-box" onSubmit={this.updatepass.bind(this)}>
						<div>
							{/* <h3 className="text-center">ACTUALIZAR CONTRASEÑA</h3> */}
						</div>
						<div className="white-space-24"></div>
						<div className="user column">
							<label>Ingresa tu nueva contraseña</label>
							<div className="white-space-8"></div>
							<input type="password" className="input input-text" name="newpassword" />
						</div>
						<div className="white-space-24"></div>
						<div className="user column">
							<label>Confirmar contraseña</label>
							<div className="white-space-8"></div>
							<input type="password" className="input input-text" name="repeatpassword" />
						</div>
						<div className="white-space-16"></div>
						<div className="butn row align-center">
							<div className="align-center ">
								
							</div>
							<div className="auto space-8">
								<button type="submit" className="btn btn-success">
									Cambiar
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		)
	}

}

export default RecoverPass;
