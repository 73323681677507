import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Consumer} from "../context";
import {URL_API} from "../core/urlsApi";
import Request from "../api";
import swal from "sweetalert";


const req = new Request();

const Recover = (props) => {
	const [pass_status, setPassStatus] = useState(false);

	async function recover(e) {
		e.preventDefault();

		//Muestra overlay
		//props.context.showLoading();

		const form = e.target;
		const user = form.username.value;
		//const pass = form.password.value;

		const data = {
			usuario: user
			//Password: pass,
		};
		const res = await req.post(URL_API + "/users/recover", data);
		if (res) {
			if (res.send) {
				swal(res.message, {
					icon: "success",
				});
				
			}else if(res.error){
				swal(res.message, {
					icon: "warning",
				});
			}
		}
	}

	return (
		<div className="home justify-center align-center">
			<div className="login">
				<form className="login-box" onSubmit={recover.bind(this)}>
					<div>
						<h2 className="text-center">Recuperar contraseña</h2>
					</div>
					<div className="white-space-24"></div>
					<div className="user column">
						<label>Ingresa tu correo</label>
						<div className="white-space-8"></div>
						<input type="email" className="input input-text" name="username" />
					</div>
					<div className="white-space-16"></div>
					<div className="butn row align-center">
						<div className="align-center ">
							<Link to="/login">Iniciar sesión</Link>
						</div>
						<div className="auto space-8">
							<button type="submit" className="btn btn-success">
								Recuperar
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Consumer(Recover);
