import React from "react";
import {Link} from "react-router-dom";

const ProjectsRow = ({ project, openProjectNav, removeProject, permission }) => {
	return (
		<tr className="table-row text-left cursor-pointer">
			<td> 
				<Link className="weight-regular cursor-pointer pointer-select" to={`/projects/terms/${project.id_proyecto}`}>
					{project.nombre} 
				</Link>
			</td>
			<td>{project.n_cliente} </td>
			{permission === 1 && <React.Fragment>
				<td className="text-center">
					<i className="fas fa-edit cursor-pointer" onClick={openProjectNav.bind(this, project)} />
				</td>
				<td className="text-center">
					<i className="fas fa-trash cursor-pointer" onClick={removeProject.bind(this, project.id_proyecto)} />
				</td>
			</React.Fragment>}
		</tr>
	);
};

export default ProjectsRow;
